<script setup lang="ts">
import { useField } from "vee-validate";
import { ref, watch } from "vue";

import { OTHER_FIELD_VALUE } from "@/utils/constants";

import SurveyRadio from "@/components/lab/survey/atoms/SurveyRadio.vue";

const props = defineProps<{
  name: string;
  values: string[];
  initialValue: string;
  hasOther?: boolean;
  error?: string;
  helpText?: string;
  mode?: "button";
}>();

const field = useField<string>(props.name, undefined, { initialValue: props.initialValue });
const otherModel = ref<string | null>(
  props.initialValue.startsWith(OTHER_FIELD_VALUE) ? props.initialValue.split(":")[1] : null,
);

// Watch the field value and reset the other model if the field value changes.
watch(
  () => field.value.value,
  () => {
    if (!field.value.value.startsWith(OTHER_FIELD_VALUE)) {
      otherModel.value = "";
    }
  },
);
</script>

<template>
  <div class="flex w-full flex-col gap-4" :class="{ 'grid grid-cols-2': mode === 'button' }">
    <SurveyRadio
      v-for="value in values"
      :key="value"
      v-model="field.value.value"
      :name="name"
      :value="value"
      :aria-label="value"
      :mode="mode"
    >
      {{ value }}
    </SurveyRadio>

    <div v-if="hasOther" class="flex w-full flex-col">
      <SurveyRadio
        v-model="field.value.value"
        :name="OTHER_FIELD_VALUE"
        :value="OTHER_FIELD_VALUE"
        :forceChecked="field.value.value.startsWith(OTHER_FIELD_VALUE)"
        aria-label="Autre"
        class="mb-4"
      >
        Autre
      </SurveyRadio>

      <textarea
        v-model="otherModel"
        :disabled="!field.value.value.startsWith(OTHER_FIELD_VALUE)"
        class="rounded bg-[#E6E9ED] px-3 py-2"
        @change="field.value.value = `${OTHER_FIELD_VALUE}${otherModel}`"
      />

      <p v-if="helpText" class="mt-1 text-xs italic text-[#6D6D6D]">
        {{ helpText }}
      </p>
    </div>

    <p v-if="error" :id="`${name}-error`" class="mt-1 text-sm text-red-700" aria-live="assertive">
      {{ error }}
    </p>
  </div>
</template>
