<script setup lang="ts">
import { computed, watch } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";

import { useUser } from "@/composables/useUser";

import AuthSection from "@/components/auth/AuthSection.vue";
import LogoHeader from "@/components/LogoHeader.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import SiteFooter from "@/components/SiteFooter.vue";

const router = useRouter();
const route = useRoute();
const user = useUser();

const currentRouteHasRegister = computed(() => {
  const registerRoutes = router.getRoutes().filter((route) => route.meta.hasRegisterBlock);

  return registerRoutes.some((registerRoute) => registerRoute.path === route.path);
});

// Scroll to top on each route change.
watch(
  () => route.name,
  () => {
    window.scrollTo(0, 0);
  },
);
</script>

<template>
  <LogoHeader />

  <NavigationMenu />

  <RouterView />

  <AuthSection v-if="currentRouteHasRegister && !user.isLoggedIn.value" />

  <SiteFooter />
</template>
