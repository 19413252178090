import { differenceInDays, differenceInHours, differenceInMinutes } from "date-fns";

export function formatRelativeDateDistance(endDate: Date) {
  const now = new Date();
  const end = new Date(endDate);

  // Calculate the differences
  const totalMinutes = differenceInMinutes(end, now);
  const totalHours = differenceInHours(end, now);
  const totalDays = differenceInDays(end, now);

  // Calculate remaining hours and minutes after extracting days
  const remainingHours = totalHours % 24;
  const remainingMinutes = totalMinutes % 60;

  // Construct the result string conditionally
  let result = "Encore ";

  if (totalDays > 0) {
    result += `${totalDays}j `;
  }

  if (remainingHours > 0) {
    result += `${remainingHours}h `;
  }

  result += `${remainingMinutes} min`;

  return result.trim();
}
