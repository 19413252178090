<template>
  <div class="flex w-full gap-3 bg-blue-lbp px-5 py-3 lg:px-20 lg:py-6">
    <img
      src="/images/logo/lbp.svg"
      alt="La Banque Postale"
      width="76"
      height="54"
      class="block h-[54px] w-auto"
    />

    <p class="mt-auto text-[30px] font-bold uppercase leading-[22px]">Le Lab</p>
  </div>
</template>
