<script setup lang="ts">
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { Dialog } from "radix-vue/namespaced";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { postLogout } from "@/api";
import { useUser } from "@/composables/useUser";

import UnsubscribeDialog from "@/components/dialogs/UnsubscribeDialog.vue";

const { t } = useI18n();
const { isLoggedIn, user } = useUser();
const queryClient = useQueryClient();
const router = useRouter();
const logout = useMutation({ mutationFn: postLogout });

const isUnsubscribeDialogOpen = ref(false);

async function handleLogout() {
  if (!user.value) {
    return;
  }

  await logout.mutateAsync(user.value.id);

  queryClient.invalidateQueries({ queryKey: ["me"] });

  router.push("/");
}
</script>

<template>
  <footer class="flex w-full flex-col items-center justify-center gap-8 bg-blue-lbp px-12 py-12">
    <div
      class="flex w-full flex-col items-center justify-center gap-3 bg-blue-lbp lg:flex-row lg:gap-6"
    >
      <button
        v-if="isLoggedIn"
        type="button"
        class="text-sm underline lg:text-base"
        @click="handleLogout"
      >
        {{ t("footer.logout") }}
      </button>

      <a href="mailto:contact@lelab.labanquepostale.fr" class="text-sm underline lg:text-base">
        {{ t("footer.contactUs") }}
      </a>

      <a
        href="/documents/politique_confidentialite.pdf"
        target="_blank"
        class="text-sm underline lg:text-base"
        v-html="t('footer.confidentialPolicy')"
      />

      <Dialog.Root>
        <!-- eslint-disable-next-line vue/attribute-hyphenation -->
        <Dialog.Trigger :asChild="true">
          <button
            type="button"
            class="text-sm underline lg:text-base"
            v-html="t('footer.legalMentions')"
          />
        </Dialog.Trigger>

        <Dialog.Portal>
          <Dialog.Overlay
            class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          />

          <!-- eslint-disable vue/no-v-text-v-html-on-component -->
          <Dialog.Content
            class="fixed left-1/2 top-1/2 z-[51] flex max-h-[90svh] w-full max-w-[calc(100vw-32px)] -translate-x-1/2 -translate-y-1/2 flex-col overflow-y-auto rounded-lg bg-white p-6 text-blue-lbp shadow-lg md:max-w-md"
            aria-describedby="undefined"
          >
            <Dialog.Title class="mb-4 text-center text-xl font-bold">
              {{ t("footer.legalMentions") }}
            </Dialog.Title>

            <Dialog.Description
              class="legal-mentions-dialog"
              v-html="t('footer.legalMentionsContent')"
            />

            <Dialog.Close class="absolute right-6 top-5 text-3xl text-black">&times;</Dialog.Close>
          </Dialog.Content>
          <!-- eslint-enable vue/no-v-text-v-html-on-component -->
        </Dialog.Portal>
      </Dialog.Root>

      <a
        href="/documents/cgu.pdf"
        target="_blank"
        class="text-sm underline lg:text-base"
        v-html="t('footer.cgu')"
      />

      <Dialog.Root
        :open="isUnsubscribeDialogOpen"
        @update:open="(e) => (isUnsubscribeDialogOpen = e)"
      >
        <!-- eslint-disable-next-line vue/attribute-hyphenation -->
        <Dialog.Trigger :asChild="true">
          <button
            v-if="isLoggedIn"
            type="button"
            class="text-sm underline lg:text-base"
            v-html="t('footer.unsubscribe')"
          />
        </Dialog.Trigger>

        <Dialog.Portal>
          <UnsubscribeDialog @close="isUnsubscribeDialogOpen = false" />
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.legal-mentions-dialog {
  font-size: 16px;
  line-height: 1.33;
}

:deep(.legal-mentions-dialog) b {
  display: block;
  text-align: center;
  font-size: 18px;
}

:deep(.legal-mentions-dialog) a {
  text-decoration: underline;
}

:deep(.legal-mentions-dialog) hr {
  height: 12px;
  border: none;
  color: transparent;
}
</style>
