import { createI18n } from "vue-i18n";

import { getTranslations } from "@/api";

interface I18nMessages {
  [lang: string]: {
    [key: string]: string;
  };
}

let i18n: ReturnType<typeof createI18n>;

export async function configureI18n() {
  const translations = (await getTranslations()).data?.results || [];

  const messages = translations.reduce(
    (acc, translation) => ({
      ...acc,
      [translation.lang]: {
        ...acc[translation.lang],
        [translation.key]: translation.value,
      },
    }),
    {} as I18nMessages,
  );

  i18n = createI18n({
    locale: "fr",
    messages,
    legacy: false,
    warnHtmlMessage: false,
    fallbackWarn: false,
  });

  return i18n;
}

export function getI18n() {
  if (!i18n) {
    throw new Error("I18n is not configured. Call configureI18n() first.");
  }

  return i18n;
}
