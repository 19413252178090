<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { z } from "zod";

import { postCheckEmail } from "@/api";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import Cta from "@/components/ui/Cta.vue";
import SignupCheckbox from "@/components/ui/SignupCheckbox.vue";
import SignupInput from "@/components/ui/SignupInput.vue";

const emit = defineEmits<{
  emailChecked: [user: { firstname: string; lastname: string; email: string }];
}>();

const { t } = useI18n();
const route = useRoute();
const checkEmail = useMutation({ mutationFn: postCheckEmail });

const registerError = ref("");

const registerSchema = toTypedSchema(
  z.object({
    firstname: z
      .string()
      .min(1, "Le prénom est obligatoire")
      .max(150, "Le prénom ne peut pas dépasser 150 caractères"),
    lastname: z
      .string()
      .min(1, "Le nom est obligatoire")
      .max(150, "Le nom ne peut pas dépasser 150 caractères"),
    email: z
      .string()
      .email("Veuillez saisir une adresse email valide")
      .min(1, "L'adresse email est obligatoire")
      .max(254, "L'adresse email ne peut pas dépasser 254 caractères"),
    cgu: z.boolean().refine((value) => value, { message: "Vous devez accepter les CGU" }),
  }),
);

const form = useForm({
  validationSchema: registerSchema,
  initialValues: { firstname: "", lastname: "", email: "", cgu: false },
});

const [firstname] = form.defineField<"firstname", string>("firstname");
const [lastname] = form.defineField<"lastname", string>("lastname");
const [email] = form.defineField<"email", string>("email");
const [cgu] = form.defineField<"cgu", boolean>("cgu");

const onSubmit = form.handleSubmit(async (values) => {
  if (checkEmail.isPending.value) {
    return;
  }

  registerError.value = "";

  try {
    const { response } = await checkEmail.mutateAsync(values.email);

    if (response.status === 400) {
      registerError.value = t("register.error.emailNotAuthorized");
    } else if (!response.ok) {
      registerError.value = t("register.error.unknownError");
    } else {
      emit("emailChecked", {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
      });
    }
  } catch {
    registerError.value = t("register.error.unknownError");
  }
});

// Pre-fill form with values from URL search query. Should be done in a watch since
// route.query may be undefined in onMounted due to asynchronous router navigation.
// p = firstname, n = lastname, e = email
watch(
  () => route.query,
  () => {
    const { p, n, e } = route.query;

    if (p && !Array.isArray(p)) {
      form.setFieldValue("firstname", p);
    }

    if (n && !Array.isArray(n)) {
      form.setFieldValue("lastname", n);
    }

    if (e && !Array.isArray(e)) {
      form.setFieldValue("email", e);
    }
  },
  { deep: true },
);
</script>

<template>
  <form
    class="mx-auto flex w-full max-w-sm flex-col lg:max-w-xl"
    novalidate
    @submit.prevent="onSubmit"
  >
    <h2 class="mb-3 text-center text-[28px] font-bold leading-[33px]">
      {{ t("register.signup.title") }}
    </h2>

    <p class="mb-8 text-center text-xs">{{ t("register.signup.mandatory") }}</p>

    <SignupInput
      v-model="firstname"
      name="firstname"
      :label="t('register.firstnameLabel')"
      class="mb-8"
      :placeholder="t('register.firstnamePlaceholder')"
      :error="form.errors.value.firstname"
    />

    <SignupInput
      v-model="lastname"
      name="lastname"
      :label="t('register.lastnameLabel')"
      class="mb-8"
      :placeholder="t('register.lastnamePlaceholder')"
      :error="form.errors.value.lastname"
    />

    <SignupInput
      v-model="email"
      name="email"
      type="email"
      :label="t('register.emailLabel')"
      class="mb-8"
      :placeholder="t('register.emailPlaceholder')"
      :error="form.errors.value.email"
    />

    <SignupCheckbox
      v-model="cgu"
      name="cgu"
      :error="form.errors.value.cgu"
      :aria-label="t('register.cguLabelA11y')"
      class="mb-8"
    >
      <span v-html="t('register.cguLabel')" />
    </SignupCheckbox>

    <Cta type="submit" class="h-14" :disabled="checkEmail.isPending.value">
      {{ t("register.signup.cta") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>

    <p v-if="registerError" class="mt-1 text-sm text-blue-darker-lbp" aria-live="polite">
      {{ registerError }}
    </p>

    <p class="mt-6 text-sm text-white">{{ t("register.mentions") }}</p>
  </form>
</template>
