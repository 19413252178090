<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { postUnsubscribeConfirm } from "@/api";
import { useUser } from "@/composables/useUser";

const unsubscribeError = ref("");

const { t } = useI18n();
const { user } = useUser();
const unsubcribe = useMutation({ mutationFn: postUnsubscribeConfirm });

async function handleUnsubscribe() {
  if (!user.value?.id || unsubcribe.isPending.value || unsubcribe.isSuccess.value) {
    return;
  }

  unsubscribeError.value = "";

  try {
    const { response } = await unsubcribe.mutateAsync(user.value.id);

    if (!response.ok) {
      unsubscribeError.value = t("unsubscribeConfirm.unknownError");
    }
  } catch {
    unsubscribeError.value = t("unsubscribeConfirm.unknownError");
  }
}

onMounted(() => {
  handleUnsubscribe();
});
</script>

<template>
  <div class="mx-auto flex min-h-screen flex-col px-5 py-12 lg:min-h-screen-header-lg lg:max-w-xl">
    <h1 class="mb-4 text-2xl font-bold text-blue-lbp lg:text-center">
      {{ t("unsubscribeConfirm.title") }}
    </h1>

    <h1 class="text-base/5 text-blue-lbp lg:text-center">
      {{ t("unsubscribeConfirm.description") }}
    </h1>

    <p v-if="unsubscribeError" class="mt-1 text-sm text-red-700 lg:text-center" aria-live="polite">
      {{ unsubscribeError }}
    </p>
  </div>
</template>
