<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

import { useSurveys } from "@/composables/useSurveys";
import { useUser } from "@/composables/useUser";

import ArrowLongLeftIcon from "@/components/icons/ArrowLongLeftIcon.vue";
import SurveyForm from "@/components/lab/survey/SurveyForm.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { surveys } = useSurveys();

const survey = computed(() => surveys.value?.find((survey) => survey.id === route.params?.id));
const { user } = useUser();
</script>

<template>
  <main
    v-if="!survey"
    class="flex min-h-screen flex-col items-center justify-center gap-8 bg-gradient-to-tr from-[#04478A] to-[#401F52]"
  >
    <h1 class="text-center text-3xl font-bold text-white">{{ t("clientLab.surveyNotFound") }}</h1>

    <button
      class="flex items-center justify-center text-center text-lg text-white underline"
      type="button"
      @click="router.push({ name: 'espace-lab.accueil' })"
    >
      {{ t("clientLab.back") }}
    </button>
  </main>

  <main v-else class="flex w-full flex-col">
    <div
      class="flex w-full flex-col bg-gradient-to-tr from-[#04478A] to-[#401F52] px-5 py-8 lg:p-20"
    >
      <button
        type="button"
        class="mb-8 flex items-center underline"
        @click="router.push({ name: 'espace-lab.accueil' })"
      >
        <ArrowLongLeftIcon class="mr-1.5" />
        {{ t("clientLab.back") }}
      </button>

      <div class="flex w-full flex-col bg-[#ffffff17] p-8 backdrop-blur-xl lg:max-w-xl lg:p-10">
        <h2
          class="mb-8 text-[32px] font-bold leading-[38px] lg:mb-6 lg:text-[42px] lg:leading-[50px]"
        >
          {{ survey.title }}
        </h2>

        <p class="mb-8 text-base/5" v-html="survey.description" />

        <p v-if="user && user.can_win_prize" class="text-base/5">
          {{ t("clientLab.surveyIncentive") }}
        </p>

        <a
          :href="`#${survey.id}`"
          class="mt-8 flex items-center justify-center rounded border border-blue-lbp bg-white px-8 py-3 text-base text-blue-lbp hover:bg-opacity-85 focus:outline-none focus:ring-2 focus:ring-blue-lbp focus:ring-opacity-30 disabled:opacity-50"
        >
          {{ t("clientLab.scrollToSurvey") }}
        </a>
      </div>
    </div>

    <section
      class="flex w-full bg-white lg:items-center lg:justify-center lg:bg-[#E5E9ED] lg:py-24"
    >
      <SurveyForm :id="survey.id" />
    </section>
  </main>
</template>
