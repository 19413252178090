<script setup lang="ts">
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { localize } from "@vee-validate/i18n";
import { required } from "@vee-validate/rules";
import { configure, defineRule, useForm } from "vee-validate";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { postSurveyAnswers } from "@/api";
import { useSurveyQuestionFields } from "@/composables/useSurveyQuestionFields";
import { useSurveyQuestions } from "@/composables/useSurveyQuestions";
import type { components } from "@/types/openapi.interfaces";
import { OTHER_FIELD_VALUE } from "@/utils/constants";

import ArrowLongLeftIcon from "@/components/icons/ArrowLongLeftIcon.vue";
import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import SurveyCheckboxField from "@/components/lab/survey/fields/SurveyCheckboxField.vue";
import SurveyRadioField from "@/components/lab/survey/fields/SurveyRadioField.vue";
import SurveyScaleRatingField from "@/components/lab/survey/fields/SurveyScaleRatingField.vue";
import SurveyTextareaField from "@/components/lab/survey/fields/SurveyTextareaField.vue";
import SurveyProgressBar from "@/components/lab/survey/SurveyProgressBar.vue";
import Cta from "@/components/ui/Cta.vue";

type FieldAnswer = string | string[];

const props = defineProps<{ id: string }>();

defineRule("required", required);

configure({
  generateMessage: localize("en", { messages: { required: "Ce champ est requis." } }),
});

const router = useRouter();
const postSurvey = useMutation({ mutationFn: postSurveyAnswers });
const { t } = useI18n();
const queryClient = useQueryClient();
const { questions } = useSurveyQuestions(props.id);

const formValues = ref<{ [key: string]: FieldAnswer }>({});
const submitError = ref("");
const currentQuestionIndex = ref(0);

const currentQuestion = computed(() => questions.value?.[currentQuestionIndex.value]);
const currentQuestionId = computed(() => currentQuestion.value?.id);

const isLastQuestion = computed(() => currentQuestionIndex.value + 1 >= questions.value!.length);

const { questionFields } = useSurveyQuestionFields(props.id, currentQuestionId);

// Dynamically create the form schema based on the question fields.
const formSchema = computed(() => {
  // Get the question fields, reduce them to an object like this: { [fieldId]: "validation rules" }
  return (questionFields.value ?? []).reduce<{ [key: string]: string }>((acc, field) => {
    acc[field.id] = field.required ? "required" : "";

    return acc;
  }, {});
});

const form = useForm<{ [key: string]: FieldAnswer }>({ validationSchema: formSchema });

/**
 * Some values can accept a free answer, in this case, the value is prefixed
 * with the content of `OTHER_FIELD_VALUE`. This function parses the values
 * and returns the actual value.
 *
 * @param values Array of values from a question field.
 */
function parseValues(values: string[]): string[] {
  return values.map((value) => {
    if (value.startsWith(OTHER_FIELD_VALUE)) {
      return value.split(":")[1] || "Autre";
    }

    return value;
  });
}

async function submitForm() {
  if (postSurvey.isPending.value) {
    return;
  }

  submitError.value = "";

  try {
    const { response } = await postSurvey.mutateAsync({
      surveyId: props.id,
      payload: Object.entries(formValues.value)
        .map(([key, value]) => ({ field_id: key, value: !Array.isArray(value) ? [value] : value }))
        .map((answer) => ({ ...answer, value: parseValues(answer.value) }))
        // If answer has an empty string, it comes from a skipped conditional question. We can safely remove it.
        .filter((answer) => answer.value.every((value) => value !== "")),
    });

    if (!response.ok) {
      submitError.value = t("survey.form.unknownError");
    } else {
      router.push({ name: "espace-lab.questionnaire-confirmation", params: { id: props.id } });
    }
  } catch {
    submitError.value = t("survey.form.unknownError");
  }
}

function isQuestionHidden(question: components["schemas"]["Question"] | undefined) {
  if (!question) {
    return false;
  }

  const nextQuestionConditionFieldId = question.display_condition_field_id;
  const nextQuestionConditionFieldValue = question.display_condition_field_value;

  if (nextQuestionConditionFieldId && nextQuestionConditionFieldValue) {
    const conditionFieldValue = formValues.value[nextQuestionConditionFieldId];

    if (
      conditionFieldValue &&
      nextQuestionConditionFieldValue &&
      !conditionFieldValue.includes(nextQuestionConditionFieldValue)
    ) {
      return true;
    }
  }
  return false;
}

/* Reset fields for a hidden question */
function resetFields(question: components["schemas"]["Question"] | undefined) {
  if (!question) {
    return false;
  }
  const currentQuestionFieldsData = queryClient.getQueryData<{
    data: components["schemas"]["Field"][];
  }>(["survey", props.id, "question", question.id]);

  currentQuestionFieldsData?.data.forEach((field) => {
    delete formValues.value[field.id];
  });
}

function getQuestionFromIndex(index: number) {
  return questions.value?.[index];
}

const onNext = form.handleSubmit(async (values) => {
  formValues.value = { ...formValues.value, ...values };

  currentQuestionIndex.value += 1;

  while (getQuestionFromIndex(currentQuestionIndex.value)) {
    if (isQuestionHidden(getQuestionFromIndex(currentQuestionIndex.value))) {
      resetFields(getQuestionFromIndex(currentQuestionIndex.value));
      currentQuestionIndex.value += 1;
    } else {
      break;
    }
  }

  if (!getQuestionFromIndex(currentQuestionIndex.value)) {
    submitForm();
  }
});

function onBack() {
  currentQuestionIndex.value -= 1;

  while (getQuestionFromIndex(currentQuestionIndex.value)) {
    if (isQuestionHidden(getQuestionFromIndex(currentQuestionIndex.value))) {
      resetFields(getQuestionFromIndex(currentQuestionIndex.value));
      currentQuestionIndex.value -= 1;
    } else {
      break;
    }
  }
}

// On question change, reset the form values.
watch(
  () => questionFields.value,
  () => {
    // `force: true` is important, otherwise it doesn't force the delete of previous questions values.
    form.resetForm({ errors: {}, values: {}, submitCount: 0 }, { force: true });
  },
);
</script>

<template>
  <form
    v-if="questions && currentQuestion"
    :id="props.id"
    class="flex w-full flex-col lg:mx-auto lg:max-w-3xl lg:bg-white"
    novalidate
    @submit.prevent="onNext"
  >
    <SurveyProgressBar :questions="questions.length" :currentQuestionIndex="currentQuestionIndex" />

    <div class="flex w-full flex-col items-start p-8 text-blue-lbp lg:p-10">
      <button
        v-if="currentQuestionIndex > 0"
        type="button"
        class="mb-8 flex items-center underline"
        @click="onBack"
      >
        <ArrowLongLeftIcon class="mr-1.5" />
        {{ t("clientLab.back") }}
      </button>

      <p class="mb-5 text-sm">{{ currentQuestionIndex + 1 }}/{{ questions.length }}</p>

      <h1 class="question-title text-xl font-bold" v-html="currentQuestion.title" />

      <p
        v-if="currentQuestion.description"
        class="mt-4 text-sm italic"
        v-html="currentQuestion.description"
      />

      <div
        v-for="questionField in questionFields"
        :key="questionField.id"
        class="my-8 flex w-full flex-col"
      >
        <h2 v-if="questionField.label" class="mb-6" v-html="questionField.label" />

        <SurveyRadioField
          v-if="questionField.field_type === 'radio'"
          :name="questionField.id"
          :values="questionField.choices"
          :initialValue="(formValues[questionField.id] as string | undefined) || ''"
          :hasOther="questionField.is_free_answer_enabled"
          :error="form.errors.value[questionField.id]"
          :mode="questionField.choices.length === 2 ? 'button' : undefined"
          :helpText="questionField.help_text"
        />

        <SurveyCheckboxField
          v-else-if="
            questionField.field_type === 'checkboxes' || questionField.field_type === 'checkbox'
          "
          :name="questionField.id"
          :values="questionField.choices"
          :initialValue="(formValues[questionField.id] as string[] | undefined) || []"
          :hasOther="questionField.is_free_answer_enabled"
          :error="form.errors.value[questionField.id]"
          :helpText="questionField.help_text"
        />

        <SurveyScaleRatingField
          v-else-if="questionField.field_type === 'scale'"
          :name="questionField.id"
          :values="
            Array.from(
              { length: questionField.scale_max! - questionField.scale_min! + 1 },
              (_, i) => (i + questionField.scale_min!).toString(),
            )
          "
          :initialValue="(formValues[questionField.id] as string | undefined) || ''"
          :minLabel="questionField.scale_min_help_text || ''"
          :maxLabel="questionField.scale_max_help_text || ''"
          :error="form.errors.value[questionField.id]"
        />

        <SurveyTextareaField
          v-else-if="questionField.field_type === 'multiline'"
          :name="questionField.id"
          :initialValue="(formValues[questionField.id] as string | undefined) || ''"
          :helpText="questionField.help_text"
          :error="form.errors.value[questionField.id]"
        />
      </div>

      <Cta v-if="!isLastQuestion" type="submit">Suivant</Cta>

      <Cta v-else-if="isLastQuestion" type="submit">
        Valider
        <ArrowLongRightIcon class="ml-1.5 size-5" />
      </Cta>

      <p class="mt-6 text-sm text-blue-lbp">{{ t("survey.form.mentions") }}</p>
    </div>
  </form>
</template>

<style lang="scss" scoped>
:deep(.question-title) span {
  font-weight: 400;
}
</style>
