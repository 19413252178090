<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { useUser } from "@/composables/useUser";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import Cta from "@/components/ui/Cta.vue";

const { t } = useI18n();
const user = useUser();

function scrollToRegister() {
  const register = document.getElementById("register-section");

  return register && register.scrollIntoView({ behavior: "smooth" });
}
</script>

<template>
  <main class="flex min-h-screen-header w-full flex-col lg:min-h-screen-header-lg lg:flex-row">
    <img
      src="/images/rewards-cover@2x.jpg"
      alt="Une famille souriante regardant un film au cinéma, avec des bols de popcorn."
      class="h-auto w-full object-cover object-left lg:order-1 lg:w-1/2"
    />

    <div class="relative flex items-center justify-center px-5 py-8 lg:w-1/2">
      <img
        src="/images/lbp-background.jpg"
        alt=""
        role="presentation"
        class="pointer-events-none absolute inset-0 h-full w-full object-cover object-center"
      />

      <div
        class="relative flex w-full flex-col items-start gap-5 bg-[#003DA524] p-8 backdrop-blur-xl lg:max-w-xl lg:px-10 lg:py-14"
      >
        <h1 class="text-[32px] font-bold leading-[38px]">{{ t("rewards.title") }}</h1>

        <p class="text-base/5" v-html="t('rewards.description')" />

        <Cta
          v-if="!user.isLoggedIn.value"
          class="mt-8 hidden min-w-[288px] lg:flex"
          @click="scrollToRegister"
        >
          {{ t("rewards.cta") }}
          <ArrowLongRightIcon class="ml-1.5 size-4" />
        </Cta>
      </div>
    </div>
  </main>
</template>
