import { useQuery } from "@tanstack/vue-query";
import { computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { getSubmissions } from "@/api";

export function useSubmissions() {
  const { data } = useQuery({ queryKey: ["submissions"], queryFn: getSubmissions });
  const router = useRouter();
  const submissions = computed(() => data.value?.data);

  watchEffect(() => {
    if (data.value?.response.status === 403) {
      void router.push({ name: "lab" });
    }
  });

  return { submissions };
}
