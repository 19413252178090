<script setup lang="ts">
import emblaCarouselVue from "embla-carousel-vue";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

import { useUser } from "@/composables/useUser";

import CarouselPagination from "@/components/carousel/CarouselPagination.vue";
import CarouselSlide from "@/components/carousel/CarouselSlide.vue";
import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import Cta from "@/components/ui/Cta.vue";

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true, align: "center" });

const { t } = useI18n();
const user = useUser();

const activeSlide = ref(0);

const itemsLength = Number.parseInt(t("yourCommitments.itemsLength"), 10) || 3;

const items = Array.from({ length: itemsLength }, (_, index) => ({
  title: t(`yourCommitments.items.${index + 1}.title`),
  description: t(`yourCommitments.items.${index + 1}.description`),
}));

function scrollToRegister() {
  const register = document.getElementById("register-section");

  if (register) {
    register.scrollIntoView({ behavior: "smooth" });
  }
}

onMounted(() => {
  emblaApi.value?.on("select", () => {
    activeSlide.value = emblaApi.value?.selectedScrollSnap() ?? 0;
  });
});
</script>

<template>
  <main
    class="relative flex min-h-screen-header w-full flex-col px-5 py-12 lg:min-h-screen-header-lg lg:p-20"
  >
    <img
      src="/images/lbp-background.jpg"
      alt=""
      role="presentation"
      class="pointer-events-none absolute inset-0 h-full w-full object-cover object-center"
    />

    <h1 class="relative mb-8 text-[32px] font-bold leading-[38px]">
      {{ t("yourCommitments.title") }}
    </h1>

    <div
      class="relative flex w-full flex-col items-start gap-5 bg-[#003DA524] p-8 backdrop-blur-xl lg:hidden"
    >
      <CarouselPagination :items="items.length" :activeSlideIndex="activeSlide" class="lg:hidden" />

      <div ref="emblaRef" class="w-full overflow-hidden lg:hidden">
        <div class="flex">
          <CarouselSlide
            v-for="slide in items"
            :key="slide.title"
            :title="slide.title"
            :description="slide.description"
          />
        </div>
      </div>
    </div>

    <div class="hidden grid-cols-3 gap-8 lg:grid">
      <div
        v-for="item in items"
        :key="item.title"
        class="flex w-full flex-col bg-[#003DA524] px-8 py-10 backdrop-blur-xl"
      >
        <h2 class="mb-8 text-2xl/7 font-bold">{{ item.title }}</h2>

        <p class="text-base/5">{{ item.description }}</p>
      </div>
    </div>

    <Cta
      v-if="!user.isLoggedIn.value"
      class="relative mx-auto mt-8 hidden w-[288px] lg:flex"
      @click="scrollToRegister"
    >
      {{ t("yourCommitments.cta") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>
  </main>
</template>
