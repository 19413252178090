<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import ArrowLongLeftIcon from "@/components/icons/ArrowLongLeftIcon.vue";

const { t } = useI18n();
const router = useRouter();
</script>

<template>
  <main class="flex w-full flex-col">
    <div
      class="flex w-full flex-col bg-gradient-to-tr from-[#04478A] to-[#401F52] px-5 py-8 lg:p-20"
    >
      <button
        type="button"
        class="mb-8 flex items-center underline"
        @click="router.push({ name: 'espace-lab.accueil' })"
      >
        <ArrowLongLeftIcon class="mr-1.5" />
        {{ t("clientLab.back") }}
      </button>

      <div class="flex w-full flex-col bg-[#ffffff17] p-8 backdrop-blur-xl lg:max-w-xl lg:p-10">
        <p class="mb-8 text-sm opacity-50 lg:mb-6">Terminé</p>

        <h2
          class="mb-8 text-[32px] font-bold leading-[38px] lg:mb-6 lg:text-[42px] lg:leading-[50px]"
        >
          Le Crédit Conso à impact
        </h2>

        <p class="mb-8 text-base/5">
          Nouveaux services, initiatives responsables, innovations, outil de gestion de budget,
          nouvelles applications ? Vous avez des idées pour améliorer notre relation banque-client ?
          Dites-nous tout, on vous écoute !
        </p>

        <p class="text-base/5">
          Découvrez les résultats des sondages et questionnaires auxquels vous avez participé.
        </p>
      </div>
    </div>

    <section
      class="flex w-full bg-white lg:min-h-screen lg:items-center lg:justify-center lg:bg-[#E5E9ED]"
    >
      <h1 class="text-2xl font-bold text-blue-lbp">Placeholder pour l'image des résulats...</h1>
    </section>
  </main>
</template>
