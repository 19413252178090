import createClient from "openapi-fetch";

import type { paths } from "@/types/openapi.interfaces";

const client = createClient<paths>();

function getDefaultHeaders() {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRFToken": getCsrfToken(),
  };
}

/**
 * Retrieve the CSRF token initially set by the server. Stored as an unsafe,
 * non-HTTPS cookie as `csrftoken` token. Extract the token from the cookie and return it.
 */
export function getCsrfToken() {
  const csrfToken = document.cookie.split("; ").find((row) => row.startsWith("csrftoken"));

  if (!csrfToken) {
    return "";
  }

  return csrfToken.split("=")[1];
}

export async function getTranslations() {
  return client.GET("/api/translations/");
}

export async function postCheckEmail(email: string) {
  return client.POST("/api/users/check-email/", {
    headers: { ...getDefaultHeaders() },
    body: { email },
  });
}

export async function postRegister({
  firstname,
  lastname,
  email,
  password,
}: {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}) {
  return client.POST("/api/users/", {
    headers: { ...getDefaultHeaders() },
    body: { first_name: firstname, last_name: lastname, email, password },
  });
}

export async function postLogin({ email, password }: { email: string; password: string }) {
  return client.POST("/api/users/login/", {
    headers: { ...getDefaultHeaders() },
    body: { email, password },
  });
}

export async function postResetPassword(email: string) {
  return client.POST("/api/users/reset-password/", {
    headers: { ...getDefaultHeaders() },
    body: { email },
  });
}

export async function postResetPasswordConfirm({
  password,
  token,
}: {
  password: string;
  token: string;
}) {
  return client.POST("/api/users/reset-password/confirm", {
    headers: { ...getDefaultHeaders() },
    body: { password, token },
  });
}

export async function getMe() {
  const { response, data } = await client.GET("/api/users/me/", {
    headers: { ...getDefaultHeaders() },
  });

  // 403 is forbidden, user is not logged-in.
  if (response.status === 403) {
    return null;
  }

  return data;
}

export async function postLogout(userId: string) {
  return client.POST("/api/users/{id}/logout/", {
    params: { path: { id: userId } },
    headers: { ...getDefaultHeaders() },
    redirect: "manual",
  });
}

export async function getSurveys() {
  return client.GET("/api/surveys", {
    headers: { ...getDefaultHeaders() },
  });
}

export async function getSurveyQuestions(surveyId: string) {
  return client.GET("/api/surveys/{survey_id}/questions", {
    params: { path: { survey_id: surveyId } },
    headers: { ...getDefaultHeaders() },
  });
}

export async function getSurveyQuestionFields(surveyId: string, questionId: string) {
  return client.GET("/api/surveys/{survey_id}/questions/{question_id}/fields", {
    params: { path: { survey_id: surveyId, question_id: questionId } },
    headers: { ...getDefaultHeaders() },
  });
}

export async function postSurveyAnswers({
  surveyId,
  payload,
}: {
  surveyId: string;
  payload: { field_id: string; value: string[] }[];
}) {
  return client.POST("/api/surveys/{survey_id}/submit", {
    params: { path: { survey_id: surveyId } },
    headers: { ...getDefaultHeaders() },
    body: payload,
  });
}

export async function postUnsubscribe(userId: string) {
  return client.POST("/api/users/{id}/unsubscribe/", {
    params: { path: { id: userId } },
    headers: { ...getDefaultHeaders() },
  });
}

export async function postUnsubscribeConfirm(userId: string) {
  return client.POST("/api/users/{id}/unsubscribe/confirm/", {
    params: { path: { id: userId } },
    headers: { ...getDefaultHeaders() },
  });
}

export async function getSubmissions() {
  return client.GET("/api/submissions", {
    headers: { ...getDefaultHeaders() },
  });
}
