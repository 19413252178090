/* eslint-disable ts/no-unsafe-argument */
import "./index.css";

import createSanitize from "@guild-frontend/vue-sanitize";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createApp, type Directive } from "vue";

import App from "./App.vue";
import { configureI18n } from "./i18n";
import { router } from "./router";

// Initial mount of the app should wait for translations to be fetched & loaded.
// eslint-disable-next-line antfu/no-top-level-await
const i18n = await configureI18n();
const app = createApp(App);

app.use(VueQueryPlugin);
app.use(router);
app.use(i18n);
app.directive("sanitize", createSanitize() as Directive<HTMLElement, string>);
app.mount("#app");
