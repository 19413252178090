<script setup lang="ts">
import { useField } from "vee-validate";
import { computed, ref, watch } from "vue";

import { OTHER_FIELD_VALUE } from "@/utils/constants";

import SurveyCheckbox from "@/components/lab/survey/atoms/SurveyCheckbox.vue";

const props = defineProps<{
  name: string;
  values: string[];
  initialValue: string[];
  hasOther?: boolean;
  error?: string;
  helpText?: string;
}>();

const field = useField<string[]>(props.name, undefined, { initialValue: props.initialValue });
const otherModel = ref<string | null>(
  field.value.value.find((value) => value.startsWith(OTHER_FIELD_VALUE))?.split(":")[1] ?? null,
);
const hasCheckedOther = computed(
  () => !!field.value.value.find((value) => value.startsWith(OTHER_FIELD_VALUE)),
);

function handleOtherChange(event: Event) {
  const target = event.target as HTMLTextAreaElement;
  const nonOtherValues = field.value.value.filter((value) => !value.startsWith(OTHER_FIELD_VALUE));

  field.value.value = [...nonOtherValues, `${OTHER_FIELD_VALUE}${target.value}`];
}

// Watch the field value and reset the other model if the field value changes.
watch(
  () => field.value.value,
  () => {
    if (!hasCheckedOther.value) {
      otherModel.value = "";
    }
  },
  { deep: true },
);
</script>

<template>
  <div class="flex w-full flex-col gap-4">
    <SurveyCheckbox
      v-for="value in values"
      :key="value"
      v-model="field.value.value"
      :name="name"
      :value="value"
      :aria-label="value"
    >
      {{ value }}
    </SurveyCheckbox>

    <div v-if="hasOther" class="flex w-full flex-col">
      <SurveyCheckbox
        v-model="field.value.value"
        :name="OTHER_FIELD_VALUE"
        :value="OTHER_FIELD_VALUE"
        :forceChecked="hasCheckedOther"
        aria-label="Autre"
        class="mb-4"
      >
        Autre
      </SurveyCheckbox>

      <textarea
        v-model="otherModel"
        :disabled="!hasCheckedOther"
        class="rounded bg-[#E6E9ED] px-3 py-2"
        @change="handleOtherChange"
      />

      <p v-if="helpText" class="mt-1 text-xs italic text-[#6D6D6D]">
        {{ helpText }}
      </p>
    </div>

    <p v-if="error" :id="`${name}-error`" class="mt-1 text-sm text-red-700" aria-live="assertive">
      {{ error }}
    </p>
  </div>
</template>
