<script setup lang="ts">
import XMarkIcon from "@/components/icons/XMarkIcon.vue";

defineProps<{
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  error?: string;
  state?: "error";
}>();

const model = defineModel<string>({ required: true });
</script>

<template>
  <div class="flex w-full flex-col">
    <label :for="name" class="mb-3 text-base">{{ label }}</label>

    <div class="relative flex w-full items-center">
      <input
        :id="name"
        v-model="model"
        :name="name"
        class="flex h-12 w-full items-center border bg-white px-4 text-base/none text-black placeholder-[#707070] focus:outline-none"
        :class="{
          'border-[#B3B3B3] focus:border-cyan-lbp': !error,
          'border-[#FF3B30] focus:border-[#FF3B30]': error || state === 'error',
        }"
        :placeholder="placeholder"
        :type="type || 'text'"
        :aria-describedby="error ? `${name}-error` : undefined"
      />

      <XMarkIcon v-if="error" class="absolute right-4 size-6 text-[#FF3B30]" />
    </div>

    <p
      v-if="error"
      :id="`${name}-error`"
      class="mt-1 text-sm text-blue-darker-lbp"
      aria-live="assertive"
    >
      {{ error }}
    </p>
  </div>
</template>
