<script setup lang="ts">
import CheckIcon from "@/components/icons/CheckIcon.vue";

defineProps<{
  name: string;
  ariaLabel?: string;
  error?: string;
}>();

const model = defineModel<boolean>({ required: true });
</script>

<template>
  <div class="flex w-full flex-col">
    <div class="flex items-center gap-2">
      <button
        type="button"
        class="flex size-6 items-center justify-center rounded border border-white bg-transparent"
        @click="model = !model"
      >
        <span v-if="ariaLabel" class="sr-only">{{ ariaLabel }}</span>

        <CheckIcon
          class="size-5 transition-opacity duration-200 ease-out"
          :class="model ? 'opacity-100' : 'opacity-0'"
        />
      </button>

      <label
        :for="name"
        aria-hidden="true"
        :aria-describedby="error ? `${name}-error` : undefined"
        @click="model = !model"
      >
        <slot />
      </label>
    </div>

    <p
      v-if="error"
      :id="`${name}-error`"
      class="mt-1 text-sm text-blue-darker-lbp"
      aria-live="assertive"
    >
      {{ error }}
    </p>
  </div>
</template>
