import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";

import { getMe } from "@/api";

export function useUser() {
  const { data } = useQuery({ queryKey: ["me"], queryFn: getMe });

  const user = computed(() => data.value);
  const isLoggedIn = computed(() => data.value?.email);

  return { user, isLoggedIn };
}
