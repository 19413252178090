<script setup lang="ts">
defineProps<{
  name: string;
  helpText?: string;
}>();

const model = defineModel<string>({ required: true });
</script>

<template>
  <div class="flex w-full flex-col">
    <textarea v-model="model" class="w-full resize-none rounded-lg bg-[#E6E9ED] px-3 py-2" />

    <p v-if="helpText" class="mt-1 text-xs italic text-[#6D6D6D]">{{ helpText }}</p>
  </div>
</template>
