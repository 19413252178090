<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { RouterLink, useRoute, useRouter } from "vue-router";

import { useUser } from "@/composables/useUser";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const user = useUser();

const links = computed(() => {
  // Only include routes that have a `headerMenuI18nKey` meta field.
  const routes = router.getRoutes().filter((route) => !!route.meta?.headerMenuI18nKey);

  const mapRoute = (route: (typeof routes)[0]) => ({
    path: route.path,
    name: t(route.meta?.headerMenuI18nKey || "unknown"),
  });

  // When authenticated, return all available routes.
  if (user.isLoggedIn.value) {
    return routes.map(mapRoute);
  }

  // When not authenticated, return only routes that don't require authentication with `requiresAuth = false`.
  return routes.filter((route) => !route.meta?.requiresAuth).map(mapRoute);
});
</script>

<template>
  <nav class="flex w-full gap-10 overflow-x-auto bg-white px-5 py-7 lg:gap-[52px] lg:px-20">
    <RouterLink
      v-for="link in links"
      :key="link.path"
      :to="link.path"
      class="whitespace-nowrap text-lg/5 transition-colors duration-200 ease-out lg:text-xl/6"
      :class="{
        'font-bold text-blue-lbp': route.path === link.path,
        'text-[#4A4A4A] hover:text-blue-lbp': route.path !== link.path,
      }"
    >
      {{ link.name }}
    </RouterLink>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

nav::-webkit-scrollbar {
  display: none;
}
</style>
