<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { z } from "zod";

import { postRegister } from "@/api";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import CheckIcon from "@/components/icons/CheckIcon.vue";
import XMarkIcon from "@/components/icons/XMarkIcon.vue";
import Cta from "@/components/ui/Cta.vue";
import SignupInput from "@/components/ui/SignupInput.vue";

const props = defineProps<{
  partialUser: { firstname: string; lastname: string; email: string } | null;
}>();

const { t } = useI18n();
const router = useRouter();
const register = useMutation({ mutationFn: postRegister });

const registerError = ref("");
const errors = ref({
  minLength: false,
  uppercase: false,
  lowercase: false,
  number: false,
  special: false,
});

const registerSchema = toTypedSchema(
  z
    .object({
      password: z
        .string()
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .max(128, "Le mot de passe ne peut pas dépasser 128 caractères")
        .regex(/[A-Z]/, "Le mot de passe doit contenir au moins une lettre majuscule")
        .regex(/[a-z]/, "Le mot de passe doit contenir au moins une lettre minuscule")
        .regex(/\d/, "Le mot de passe doit contenir au moins un chiffre")
        .regex(/[#?!@$%^&*-]+/, "Le mot de passe doit contenir au moins un caractère spécial"),
      confirmPassword: z.string(),
    })
    .refine((values) => values.password === values.confirmPassword, {
      message: "Les mots de passe doivent être identiques",
      path: ["confirmPassword"],
    }),
);

const form = useForm({
  validationSchema: registerSchema,
  initialValues: { password: "", confirmPassword: "" },
});

const [password] = form.defineField<"password", string>("password");
const [confirmPassword] = form.defineField<"confirmPassword", string>("confirmPassword");

const onSubmit = form.handleSubmit(async (values) => {
  if (register.isPending.value) {
    return;
  }

  registerError.value = "";

  if (!props.partialUser) {
    registerError.value = t("register.error.unknownError");
    return;
  }

  try {
    const { response } = await register.mutateAsync({
      firstname: props.partialUser.firstname,
      lastname: props.partialUser.lastname,
      email: props.partialUser.email,
      password: values.password,
    });

    if (!response.ok) {
      registerError.value = t("register.error.unknownError");
    } else {
      router.push({ name: "espace-lab.accueil" });
    }
  } catch {
    registerError.value = t("register.error.unknownError");
  }
});

watch(
  () => password.value,
  () => {
    errors.value.minLength = password.value.length < 8;
    errors.value.uppercase = !/[A-Z]/.test(password.value);
    errors.value.lowercase = !/[a-z]/.test(password.value);
    errors.value.number = !/\d/.test(password.value);
    errors.value.special = !/[#?!@$%^&*-]+/.test(password.value);
  },
  { immediate: true },
);
</script>

<template>
  <form
    class="mx-auto flex w-full max-w-sm flex-col lg:max-w-xl"
    novalidate
    @submit.prevent="onSubmit"
  >
    <h2 class="mb-8 text-center text-[28px] font-bold leading-[33px]">
      {{ t("register.signup.title2") }}
    </h2>

    <SignupInput
      v-model="password"
      name="password"
      type="password"
      :label="t('register.passwordLabel')"
      class="mb-8"
    />

    <p class="mb-1">{{ t("register.signup.passwordShouldContain") }}</p>

    <!-- eslint-disable vue/padding-line-between-tags -->
    <ul class="mb-8 list-disc pl-2">
      <li class="mb-1 flex items-center gap-1">
        <span class="size-[5px] rounded-full bg-white" />
        Au moins 8 caractères
        <XMarkIcon
          v-if="errors.minLength && form.submitCount.value"
          class="size-5 rounded text-red-700"
        />
        <CheckIcon v-else-if="!errors.minLength" class="size-5 rounded text-green-700" />
      </li>

      <li class="mb-1 flex items-center gap-1">
        <span class="size-[5px] rounded-full bg-white" />
        Une lettre majuscule
        <XMarkIcon
          v-if="errors.uppercase && form.submitCount.value"
          class="size-5 rounded text-red-700"
        />
        <CheckIcon v-else-if="!errors.uppercase" class="size-5 rounded text-green-700" />
      </li>

      <li class="mb-1 flex items-center gap-1">
        <span class="size-[5px] rounded-full bg-white" />
        Une lettre minuscule
        <XMarkIcon
          v-if="errors.lowercase && form.submitCount.value"
          class="size-5 rounded text-red-700"
        />
        <CheckIcon v-else-if="!errors.lowercase" class="size-5 rounded text-green-700" />
      </li>

      <li class="mb-1 flex items-center gap-1">
        <span class="size-[5px] rounded-full bg-white" />
        Un chiffre
        <XMarkIcon
          v-if="errors.number && form.submitCount.value"
          class="size-5 rounded text-red-700"
        />
        <CheckIcon v-else-if="!errors.number" class="size-5 rounded text-green-700" />
      </li>

      <li class="flex items-center gap-1">
        <span class="size-[5px] rounded-full bg-white" />
        Un caractère spécial
        <XMarkIcon
          v-if="errors.special && form.submitCount.value"
          class="size-5 rounded text-red-700"
        />
        <CheckIcon v-else-if="!errors.special" class="size-5 rounded text-green-700" />
      </li>
    </ul>
    <!-- eslint-enable vue/padding-line-between-tags -->

    <SignupInput
      v-model="confirmPassword"
      name="confirmPassword"
      type="password"
      :label="t('register.confirmPasswordLabel')"
      class="mb-8"
      :error="form.errors.value.confirmPassword"
    />

    <Cta type="submit" class="h-14" :disabled="register.isPending.value">
      {{ t("register.signup.cta2") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>

    <p v-if="registerError" class="mt-1 text-sm text-blue-darker-lbp" aria-live="polite">
      {{ registerError }}
    </p>
  </form>
</template>
