import { useQuery } from "@tanstack/vue-query";
import { computed, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { getSurveys } from "@/api";

export function useSurveys() {
  const { data } = useQuery({ queryKey: ["surveys"], queryFn: getSurveys });
  const surveys = computed(() => data.value?.data);
  const router = useRouter();

  watchEffect(() => {
    if (data.value?.response.status === 403) {
      void router.push({ name: "lab" });
    }
  });

  return { surveys };
}
