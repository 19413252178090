<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { useSurveys } from "@/composables/useSurveys";
import { QuizStatus } from "@/types";

import QuizCard from "@/components/lab/QuizCard.vue";

const { t } = useI18n();
const { surveys } = useSurveys();

const resultsAvailableSurveys = computed(() =>
  surveys.value?.filter((survey) => survey.status === QuizStatus.RESULTS_AVAILABLE),
);
</script>

<template>
  <div class="flex w-full flex-col">
    <p
      class="mb-8 text-sm lg:mx-auto lg:max-w-xl lg:text-center lg:text-base/5"
      v-html="t('clientLab.completedTabDescription')"
    />

    <div class="mx-auto flex w-full max-w-[1352px] flex-col gap-8 lg:grid lg:grid-cols-3 lg:gap-12">
      <QuizCard
        v-for="survey in resultsAvailableSurveys"
        :id="survey.id"
        :key="survey.id"
        :title="survey.title"
        :supertitle="survey.supertitle || ''"
        :date="new Date(survey.end_at)"
        :description="survey.description || ''"
        :type="QuizStatus.RESULTS_AVAILABLE"
        :alreadySubmitted="false"
      />

      <div v-if="resultsAvailableSurveys?.length === 0" class="flex flex-col bg-white/10 p-8">
        <h2 class="mb-8 text-[32px] font-bold leading-[38px]">
          {{ t("clientLab.noAvailableResult.title") }}
        </h2>

        <p class="mb-8 text-base/5">{{ t("clientLab.noAvailableResult.description") }}</p>
      </div>
    </div>
  </div>
</template>
