import { useQuery } from "@tanstack/vue-query";
import { computed, type ComputedRef, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { getSurveyQuestionFields } from "@/api";

export function useSurveyQuestionFields(
  surveyId: string,
  questionId: ComputedRef<string | undefined | null>,
) {
  const router = useRouter();

  const enabled = computed(() => !!surveyId && !!questionId.value);

  const { data } = useQuery({
    queryKey: ["survey", surveyId, "question", questionId],
    queryFn: async () => getSurveyQuestionFields(surveyId, questionId.value!),
    enabled,
  });

  const questionFields = computed(() => data.value?.data);

  watchEffect(() => {
    if (data.value?.response.status === 403) {
      void router.push({ name: "lab" });
    }
  });

  return { questionFields };
}
