<script setup lang="ts">
import CheckIcon from "@/components/icons/CheckIcon.vue";

const props = withDefaults(
  defineProps<{
    name: string;
    value: string;
    ariaLabel?: string;
    hideLabel?: boolean;
    forceChecked?: boolean;
  }>(),
  {
    hideLabel: false,
  },
);

const model = defineModel<string[]>({ required: true });

function handleChange() {
  if (model.value.includes(props.value)) {
    model.value = model.value.filter((v) => v !== props.value);
  } else {
    model.value = [...model.value, props.value];
  }
}
</script>

<template>
  <button
    type="button"
    class="group flex shrink-0 items-center gap-2 focus:outline-none"
    @click="handleChange"
  >
    <div
      class="flex size-6 shrink-0 items-center justify-center rounded border border-blue-lbp bg-transparent group-focus:ring-2 group-focus:ring-blue-lbp/50"
    >
      <span v-if="ariaLabel" class="sr-only">{{ ariaLabel }}</span>

      <CheckIcon
        class="size-5 transition-opacity duration-200 ease-out"
        :class="model.includes(value) || forceChecked ? 'opacity-100' : 'opacity-0'"
      />
    </div>

    <label v-if="!hideLabel" :for="name" aria-hidden="true" class="text-left">
      <slot />
    </label>
  </button>
</template>
