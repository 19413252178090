<script setup lang="ts">
import { useField } from "vee-validate";

import SurveyTextarea from "@/components/lab/survey/atoms/SurveyTextarea.vue";

const props = defineProps<{
  name: string;
  initialValue: string;
  error?: string;
  helpText?: string;
}>();

const field = useField<string>(props.name, undefined, { initialValue: props.initialValue });
</script>

<template>
  <div class="flex w-full flex-col">
    <SurveyTextarea :key="name" v-model="field.value.value" :name="name" :helpText="helpText" />

    <p v-if="error" :id="`${name}-error`" class="mt-1 text-sm text-red-700" aria-live="assertive">
      {{ error }}
    </p>
  </div>
</template>
