/* eslint-disable ts/no-unsafe-assignment */
import { createRouter, createWebHistory } from "vue-router";

import { getMe } from "@/api";

import LoginView from "@/views/auth/LoginView.vue";
import PasswordResetView from "@/views/auth/PasswordResetView.vue";
import UnsubscribeConfirmation from "@/views/auth/UnsubscribeConfirmation.vue";
import ClientLabView from "@/views/dashboard/ClientLabView.vue";
import SurveyResultsView from "@/views/dashboard/SurveyResultsView.vue";
import SurveySentView from "@/views/dashboard/SurveySentView.vue";
import SurveyView from "@/views/dashboard/SurveyView.vue";
import AboutLabView from "@/views/public/AboutLabView.vue";
import AboutOurCommitmentsView from "@/views/public/AboutOurCommitmentsView.vue";
import AboutRewardsView from "@/views/public/AboutRewardsView.vue";
import AboutYourCommitmentsView from "@/views/public/AboutYourCommitmentsView.vue";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    {
      path: "/espace-lab",
      name: "espace-lab",
      meta: { headerMenuI18nKey: "header.menu.home", requiresAuth: true },
      children: [
        {
          name: "espace-lab.accueil",
          path: "",
          component: ClientLabView,
        },
        {
          name: "espace-lab.questionnaire",
          path: "questionnaire/:id",
          component: SurveyView,
        },
        {
          name: "espace-lab.questionnaire-confirmation",
          path: "questionnaire/:id/confirmation",
          component: SurveySentView,
        },
        {
          name: "espace-lab.questionnaire-resultats",
          path: "questionnaire/:id/resultats",
          component: SurveyResultsView,
        },
      ],
      beforeEnter: async (to, from, next) => {
        const user = await getMe();

        if (user) {
          next();
        } else {
          next({ path: "/" });
        }
      },
    },
    {
      path: "/",
      name: "lab",
      component: AboutLabView,
      meta: { headerMenuI18nKey: "header.menu.lab", hasRegisterBlock: true },
    },
    {
      path: "/nos-engagements",
      name: "nos-engagements",
      component: AboutOurCommitmentsView,
      meta: { headerMenuI18nKey: "header.menu.ourCommitments", hasRegisterBlock: true },
    },
    {
      path: "/vos-engagements",
      name: "vos-engagements",
      component: AboutYourCommitmentsView,
      meta: { headerMenuI18nKey: "header.menu.yourCommitments", hasRegisterBlock: true },
    },
    {
      path: "/recompenses",
      name: "recompenses",
      component: AboutRewardsView,
      meta: { headerMenuI18nKey: "header.menu.rewards", hasRegisterBlock: true },
    },
    {
      path: "/connexion",
      name: "connexion",
      component: LoginView,
    },
    {
      path: "/reinitialiser-mdp",
      name: "reinitialiser-mdp",
      component: PasswordResetView,
    },
    {
      path: "/confirmation-desinscription",
      name: "confirmation-desinscription",
      component: UnsubscribeConfirmation,
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: "lab" },
    },
  ],
});

declare module "vue-router" {
  interface RouteMeta {
    headerMenuI18nKey?: string;
    requiresAuth?: boolean;
    hasRegisterBlock?: boolean;
  }
}
