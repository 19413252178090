<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { Dialog } from "radix-vue/namespaced";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { postUnsubscribe } from "@/api";
import { useUser } from "@/composables/useUser";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import XMarkIcon from "@/components/icons/XMarkIcon.vue";
import Cta from "@/components/ui/Cta.vue";

const emit = defineEmits<{
  close: [isOpen: false];
}>();

const unsubscribeError = ref("");
const unsubscribeMessage = ref("");

const { t } = useI18n();
const { user } = useUser();
const unsubcribe = useMutation({ mutationFn: postUnsubscribe });

async function handleUnsubscribe() {
  if (!user.value?.id || unsubcribe.isPending.value || unsubcribe.isSuccess.value) {
    return;
  }

  unsubscribeError.value = "";
  unsubscribeMessage.value = "";

  try {
    const { response } = await unsubcribe.mutateAsync(user.value.id);

    if (!response.ok) {
      unsubscribeError.value = t("unsubscribeDialog.unknownError");
    } else {
      unsubscribeMessage.value = t("unsubscribeDialog.successMessage");
    }
  } catch {
    unsubscribeError.value = t("unsubscribeDialog.unknownError");
  }
}
</script>

<template>
  <Dialog.Overlay
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
  />

  <Dialog.Content
    class="fixed left-1/2 top-1/2 z-[51] flex w-full max-w-md -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-lg bg-white p-6 shadow-lg"
  >
    <Dialog.Close class="absolute right-0 top-0 mr-4 mt-4">
      <XMarkIcon class="size-6 text-blue-lbp" />
    </Dialog.Close>

    <Dialog.Title class="mb-4 max-w-xs text-center text-[28px] font-bold text-blue-lbp">
      {{ t("unsubscribeDialog.title") }}
    </Dialog.Title>

    <Dialog.Description class="mb-8 text-lg text-blue-lbp">
      {{ t("unsubscribeDialog.description") }}
    </Dialog.Description>

    <Cta class="mb-4" @click="emit('close', false)">
      {{ t("unsubscribeDialog.keepEnrolled") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>

    <Cta variant="outline" @click="handleUnsubscribe">
      {{ t("unsubscribeDialog.confirm") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>

    <p v-if="unsubscribeError" class="mt-1 text-sm text-blue-lbp" aria-live="polite">
      {{ unsubscribeError }}
    </p>

    <p v-if="unsubscribeMessage" class="mt-4 text-base/5 text-blue-lbp" aria-live="polite">
      {{ unsubscribeMessage }}
    </p>
  </Dialog.Content>
</template>
