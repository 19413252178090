<script setup lang="ts">
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import LoginForm from "@/components/auth/forms/LoginForm.vue";
import SignupForm from "@/components/auth/forms/SignupForm.vue";
import SignupFormPassword from "@/components/auth/forms/SignupFormPassword.vue";

const props = defineProps<{ forceLoginView?: boolean; fullscreen?: boolean }>();

const { t } = useI18n();

const mode = ref<"register" | "login">(props.forceLoginView ? "login" : "register");
const step = ref<0 | 1>(0);
const partialUser = ref<{ firstname: string; lastname: string; email: string } | null>(null);

function handleFirstStepRegister(user: { firstname: string; lastname: string; email: string }) {
  partialUser.value = user;
  step.value = 1;
}
</script>

<template>
  <section
    id="register-section"
    class="flex w-full flex-col overflow-x-hidden bg-cyan-lbp px-5 py-12"
    :class="{
      'min-h-screen-header items-center justify-center lg:min-h-screen-header-lg': fullscreen,
    }"
  >
    <Transition name="slide-fade" mode="out-in">
      <SignupForm
        v-if="mode === 'register' && step === 0"
        @emailChecked="handleFirstStepRegister"
      />

      <SignupFormPassword
        v-else-if="mode === 'register' && step === 1"
        :partialUser="partialUser"
      />

      <LoginForm v-else-if="mode === 'login'" />
    </Transition>

    <div v-if="!forceLoginView" class="mt-6 flex flex-col items-center">
      <p class="mb-1 text-center text-base font-bold">
        {{ mode === "register" ? t("register.alreadyMember") : t("register.notMember") }}
      </p>

      <button
        type="button"
        class="text-center text-base font-bold underline"
        @click="mode = mode === 'register' ? 'login' : 'register'"
      >
        {{ mode === "register" ? t("register.loginSwitch") : t("register.registerSwitch") }}
      </button>
    </div>
  </section>
</template>
