<script setup lang="ts">
import { useField } from "vee-validate";

import SurveyRadio from "@/components/lab/survey/atoms/SurveyRadio.vue";

const props = defineProps<{
  name: string;
  values: string[];
  initialValue: string;
  minLabel: string;
  maxLabel: string;
  error?: string;
}>();

const field = useField<string>(props.name, undefined, { initialValue: props.initialValue });
</script>

<template>
  <div class="flex w-full flex-col gap-4">
    <div class="flex w-full items-end gap-5">
      <p class="text-center text-xs/4 italic text-[#4A4A4A]" v-html="minLabel" />

      <div class="flex gap-2.5">
        <div v-for="value in values" :key="value" class="flex flex-col items-center gap-2">
          <span class="text-lg/none text-blue-lbp">{{ value }}</span>

          <SurveyRadio
            v-model="field.value.value"
            :name="name"
            :value="value"
            :aria-label="value"
            :hideLabel="true"
          />
        </div>
      </div>

      <p class="text-center text-xs/4 italic text-[#4A4A4A]" v-html="maxLabel" />
    </div>

    <p v-if="error" :id="`${name}-error`" class="mt-1 text-sm text-red-700" aria-live="assertive">
      {{ error }}
    </p>
  </div>
</template>
