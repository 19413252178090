<script setup lang="ts">
import { useMutation } from "@tanstack/vue-query";
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { z } from "zod";

import { postLogin, postResetPassword } from "@/api";

import ArrowLongRightIcon from "@/components/icons/ArrowLongRightIcon.vue";
import Cta from "@/components/ui/Cta.vue";
import SignupInput from "@/components/ui/SignupInput.vue";

const { t } = useI18n();
const router = useRouter();
const login = useMutation({ mutationFn: postLogin });
const resetPassword = useMutation({ mutationFn: postResetPassword });
const mode = ref<"login" | "forgot-password">("login");
const responseError = ref("");
const resetPasswordSent = ref(false);
const loginState = ref<"error" | undefined>(undefined);

const loginSchema = toTypedSchema(
  z.object({
    email: z
      .string()
      .email("Veuillez saisir une adresse email valide")
      .min(1, "L'adresse email est obligatoire")
      .max(254, "L'adresse email ne peut pas dépasser 254 caractères"),
    password: z
      .string()
      .min(8, "Le mot de passe doit contenir au moins 8 caractères")
      .max(128, "Le mot de passe ne peut pas dépasser 128 caractères"),
  }),
);

const passwordForgottenSchema = toTypedSchema(
  z.object({
    email: z
      .string()
      .email("Veuillez saisir une adresse email valide")
      .min(1, "L'adresse email est obligatoire")
      .max(254, "L'adresse email ne peut pas dépasser 254 caractères"),
  }),
);

const loginForm = useForm({
  validationSchema: loginSchema,
  initialValues: { email: "", password: "" },
});

const passwordForgottenForm = useForm({
  validationSchema: passwordForgottenSchema,
  initialValues: { email: "" },
});

const [email] = loginForm.defineField<"email", string>("email");
const [password] = loginForm.defineField<"password", string>("password");
const [emailForgotten] = passwordForgottenForm.defineField<"email", string>("email");

const onSubmitLogin = loginForm.handleSubmit(async (values) => {
  if (login.isPending.value) {
    return;
  }

  responseError.value = "";
  loginState.value = undefined;

  try {
    const { response } = await login.mutateAsync({
      email: values.email,
      password: values.password,
    });

    if (!response.ok) {
      responseError.value = t("register.error.invalidCredentials");
      loginState.value = "error";
    } else {
      router.push({ name: "espace-lab.accueil" });
    }
  } catch {
    responseError.value = t("register.error.unknownError");
  }
});

const onSubmitPasswordForgotten = passwordForgottenForm.handleSubmit(async (values) => {
  if (resetPassword.isPending.value) {
    return;
  }

  responseError.value = "";

  try {
    const { response } = await postResetPassword(values.email);

    if (response.ok) {
      resetPasswordSent.value = true;
    } else {
      responseError.value = t("register.error.unknownEmail");
    }
  } catch {
    responseError.value = t("register.error.unknownError");
  }
});

function handleSubmit(e: Event) {
  if (mode.value === "login") {
    onSubmitLogin(e);
  } else if (mode.value === "forgot-password") {
    onSubmitPasswordForgotten(e);
  }
}

function switchMode() {
  mode.value = mode.value === "login" ? "forgot-password" : "login";
  responseError.value = "";
  resetPasswordSent.value = false;

  loginForm.resetForm();
  passwordForgottenForm.resetForm();
}
</script>

<template>
  <form
    class="mx-auto flex w-full max-w-sm flex-col lg:max-w-xl"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <h2 class="mb-8 text-center text-[28px] font-bold leading-[33px]">
      {{ mode === "login" ? t("register.login.title") : t("register.passwordforgotten.title") }}
    </h2>

    <template v-if="mode === 'login'">
      <SignupInput
        v-model="email"
        name="email"
        type="email"
        :label="t('register.emailLabel')"
        class="mb-8"
        :placeholder="t('register.emailPlaceholder')"
        :error="loginForm.errors.value.email"
        :state="loginState"
      />

      <SignupInput
        v-model="password"
        name="password"
        type="password"
        :label="t('register.passwordLabel')"
        class="mb-4"
        :error="loginForm.errors.value.password"
        :state="loginState"
      />
    </template>

    <template v-if="mode === 'forgot-password'">
      <SignupInput
        v-show="!resetPasswordSent"
        v-model="emailForgotten"
        name="email"
        type="email"
        :label="t('register.emailLabel')"
        class="mb-8"
        :placeholder="t('register.emailPlaceholder')"
        :error="passwordForgottenForm.errors.value.email"
      />
    </template>

    <p
      v-show="resetPasswordSent"
      v-sanitize="t('register.passwordreset.emailSent')"
      class="text-sl mb-5"
    />

    <button
      type="button"
      class="mb-8 mr-auto text-sm underline"
      :disabled="login.isPending.value || resetPassword.isPending.value"
      @click="switchMode"
    >
      {{ mode === "login" ? t("register.forgotPasswordCta") : t("register.backCta") }}
    </button>

    <Cta
      v-show="mode === 'login' || !resetPasswordSent"
      type="submit"
      class="h-14"
      :disabled="login.isPending.value || resetPassword.isPending.value"
    >
      {{ mode === "login" ? t("register.login.cta") : t("register.passwordforgotten.cta") }}
      <ArrowLongRightIcon class="ml-1.5 size-4" />
    </Cta>

    <p v-if="responseError" class="mt-1 text-sm text-blue-darker-lbp" aria-live="polite">
      {{ responseError }}
    </p>
  </form>
</template>
