<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import { useUser } from "@/composables/useUser";

import ArrowLongLeftIcon from "@/components/icons/ArrowLongLeftIcon.vue";

const { t } = useI18n();
const router = useRouter();
const { user } = useUser();
</script>

<template>
  <main class="flex w-full flex-col">
    <div
      class="flex min-h-[calc(100svh-102px-80px-120px)] w-full flex-col bg-gradient-to-tr from-[#04478A] to-[#401F52] px-5 py-8 lg:p-20"
    >
      <button
        type="button"
        class="mb-8 flex items-center underline"
        @click="router.push({ name: 'espace-lab.accueil' })"
      >
        <ArrowLongLeftIcon class="mr-1.5" />
        {{ t("clientLab.surveySent.back") }}
      </button>

      <h2
        class="mb-8 text-[32px] font-bold leading-[38px] lg:mb-6 lg:text-[42px] lg:leading-[50px]"
      >
        {{ t("clientLab.surveySent.title") }}
      </h2>

      <p
        v-if="user?.can_win_prize"
        v-sanitize="t('clientLab.surveySent.description')"
        class="text-base/5"
      />

      <p v-else v-sanitize="t('clientLab.surveySent.descriptionNoMoreLot')" class="text-base/5" />
    </div>
  </main>
</template>
