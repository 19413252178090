<script setup lang="ts">
withDefaults(
  defineProps<{
    name: string;
    value: string;
    ariaLabel?: string;
    hideLabel?: boolean;
    forceChecked?: boolean;
    mode?: "button";
  }>(),
  {
    hideLabel: false,
  },
);

const model = defineModel<string>({ required: true });
</script>

<template>
  <button
    type="button"
    class="group flex shrink-0 items-center gap-2 focus:outline-none"
    :class="{ 'rounded border border-[#B3B3B3] p-4': mode === 'button' }"
    @click="model = value"
  >
    <div
      class="flex size-[18px] shrink-0 items-center justify-center rounded-full border border-blue-lbp bg-white group-focus:ring-2 group-focus:ring-blue-lbp/50"
    >
      <span v-if="ariaLabel" class="sr-only">{{ ariaLabel }}</span>

      <span
        class="size-2 rounded-full bg-blue-lbp transition-opacity duration-200 ease-out"
        :class="model === value || forceChecked ? 'opacity-100' : 'opacity-0'"
      />
    </div>

    <label
      v-if="!hideLabel"
      :for="value"
      aria-hidden="true"
      class="text-left text-base/5 text-blue-lbp"
    >
      <slot />
    </label>
  </button>
</template>
