<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  questions: number;
  currentQuestionIndex: number;
}>();

const progressionPercentage = computed(() => {
  return (props.currentQuestionIndex / props.questions) * 100;
});
</script>

<template>
  <div class="relative h-1 w-full bg-[#CCCCCC] lg:h-4">
    <span
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="progressionPercentage"
      class="absolute left-0 right-0 top-0 h-1 bg-[#0097D6] transition-all duration-500 ease-out lg:h-4"
      :style="{ width: `${progressionPercentage}%` }"
    />
  </div>
</template>
